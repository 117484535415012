<template>
  <b-card-code title="">
    <b-card-code
      no-body
      :title="`Account Managers: ${organization.org_name}`"
      style="width: 100%"
    >
      <b-card-code class="bg-light-secondary">
        <b-form inline style="display: inline-block; width: 100%">
          <b-form-select
            style="width: 35%"
            class="ml-1"
            v-model="user"
            :options="users_options"
          >
          </b-form-select>
          <b-form-select
            style="width: 35%"
            class="ml-1"
            v-model="role"
            :options="role_options"
          >
          </b-form-select>
          <b-button
            style="width: 20%"
            class="ml-1"
            variant="primary"
            @click="addManager"
          >
            Add
          </b-button>
        </b-form>
      </b-card-code>
    </b-card-code>
    <!-- {{ items }} -->
    <b-table
      responsive="sm"
      id="orgTable"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(role)="data">
        <b-badge v-if="data.item.role == 0" variant="primary"
          >Security Analyst</b-badge
        >
        <b-badge v-if="data.item.role == 1" variant="primary">Manager</b-badge>
      </template>
      <template #cell(actions)="data">
        <b-button
          variant="flat-danger"
          title="Remove"
          class="btn-icon"
          @click="remove(data.item)"
        >
          Remove
          <feather-icon icon="XCircleIcon"
        /></b-button>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="orgTable"
    />
    <!-- <span v-for="item in organization.managers" :key="item.m_id">
      <b-badge variant="info" class="mr-1"
        >{{ item.first_name }} {{ item.last_name }} ( {{ item.email }}) - {{item.role}}
        <feather-icon icon="XCircleIcon" @click="detachLabel(item)" />
      </b-badge>
    </span> -->
  </b-card-code>
</template>
<script>
import {
  BTable,
  BModal,
  BPagination,
  BPaginationNav,
  BBadge,
  BSidebar,
  BForm,
  BButton,
  VBToggle,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BRow,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BTable,
    BModal,
    BPagination,
    BPaginationNav,
    BBadge,
    BSidebar,
    BForm,
    BButton,
    VBToggle,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BRow,
    BCardCode,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      pagination_pos: "center",
      perPage: 10,
      items: [],
      // rows: 0,
      user: null,
      users_options: [{ value: null, text: "--Select User--" }],
      role: null,
      role_options: [
        { value: null, text: "--Select Role--" },
        { value: 0, text: "Security Analyst" },
        { value: 1, text: "Manager" },
      ],
      fields: [
        { key: "m_id", label: "MID" },
        { key: "profile.first_name", label: "Name" },
        { key: "profile.email", label: "Email" },
        { key: "role", label: "Role" },
        { key: "actions", label: "Actions" },
      ],
    };
  },
  created: function () {
    this.load();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    load: function () {
      // Fetch user options
      this.items = this.organization.managers;
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "user/users/get-all-users?role_type=0",
      };
      var self = this;
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text: res.data[key].username,
          };
          // console.log(res.data[key].profile.organization)
          self.users_options.push(user);
        });
      });
    },
    reload: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/" +
          this.organization.org_id,
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.items = res.data.managers;
        // this.toastMessage(res)  // method call to method2
        // this.items = res.data.results;
      });
    },
    addManager: function () {
      let data = {
        org_id: this.organization.org_id,
        users: [{ user_id: this.user, role: this.role }],
      };
      const o_options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/" +
          this.organization.org_id +
          "/add-managers/",
      };
      var self = this;
      this.$http(o_options).then((res) => {
        if ((res.status = 200)) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Added Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        self.reload();
      });
    },
    remove: function (item) {
      let data = {
        org_id: this.organization.org_id,
        users: [{ user_id: item.user_id, role: item.role }],
      };
      const o_options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/" +
          this.organization.org_id +
          "/remove-managers/",
      };
      var self = this;
      this.$http(o_options).then((res) => {
        self.reload();
      });
    },
  },
};
</script>