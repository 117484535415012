<template>
  <b-tabs>
    <b-tab title="Profile" active>
      <b-card-code title="Update">
        <!-- form -->
        <!-- {{ organization }} -->
        <validation-observer ref="OrgEditForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="editOrg">
            <!-- Name -->
            <b-form-group
              label="Organization Name"
              label-for="organization-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Organization name"
                :rules="{
                  required: true,
                  regex: /^[a-zA-Z0-9\s\.\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/,
                }"
              >
                <b-form-input
                  id="org_name"
                  v-model="org_name"
                  :state="errors.length > 0 ? false : null"
                  name="org_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Organization Address"
              label-for="organization-address"
            >
              <validation-provider
                #default="{ errors }"
                name="Organization Address"
                :rules="{
                  regex: /^[a-zA-Z0-9\s\.\,\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/,
                }"
              >
                <b-form-input
                  id="org_address"
                  v-model="org_address"
                  name="org_address"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <!-- <small class="text-danger">{{ errors[0] }}</small> -->
            </b-form-group>

            <b-form-group
              label="Organization Nick Name"
              label-for="organization-nick-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Organization nickname"
                :rules="{
                  regex: /^[a-zA-Z0-9\s\.\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/,
                }"
              >
                <b-form-input
                  id="org_nick_name"
                  v-model="org_nick_name"
                  name="org_nick_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Industry" label-for="industry">
              <v-select
                v-model="industry"
                label="text"
                taggable
                :options="industry_options"
                autocomplete
                style="width: 100%"
                :reduce="(industry) => industry.value"
              />
            </b-form-group>
            <b-form-group label="Country" label-for="country">
              <v-select
                v-model="country"
                label="text"
                taggable
                :options="country_options"
                autocomplete
                style="width: 100%"
                :reduce="(country) => country.value"
              />
            </b-form-group>

            <b-form-group label="CDR" label-for="cdr">
              <v-select
                v-model="cdr"
                label="text"
                :options="cdr_options"
                @search="loadCdr"
                autocomplete
                :reduce="(cdr) => cdr.value"
              />
            </b-form-group>
            <b-form-group label="Start Date">
              <flat-pickr
                placeholder="Start Date"
                v-model="fromDate"
                class="form-control"
              />
            </b-form-group>
            <b-button
              type="submit"
              variant="primary"
              :disabled="invalid"
              class="my-1"
              size="sm"
            >
              Update
            </b-button>
          </b-form>
        </validation-observer>
      </b-card-code>
    </b-tab>
    <b-tab title="Management" lazy>
      <Managers :organization="organization" />
    </b-tab>
    <b-tab title="Scan Settings" lazy>
      <ScanSettings :organization="organization" />
    </b-tab>
  </b-tabs>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BTabs,
  BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
// import { BTable } from 'bootstrap-vue'
// import { BPagination } from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Managers from "./components/managers.vue";
import ScanSettings from "./components/scan_settings.vue";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    Managers,
    BTabs,
    BTab,
    ScanSettings,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      org_id: "",
      org_name: "",
      org_address: "",
      org_nick_name: "",
      organization: {},
      cdr: "",
      cdr_options: [],
      country: null,
      country_options: [],
      industry: null,
      industry_options: [],
      fromDate: null,
      org_refId: this.$store.state.app.org_id,

      // rules: {
      //   org_name: [
      //     {
      //       rule: value => !/[^a-zA-Z0-9 ]/.test(value),
      //       message: 'Name must not contain special characters'
      //     }
      //   ]
      // }
    };
  },
  created: function () {
    this.load();
    this.fetchCDR();
    this.loadCountry();
    this.loadIndustry();
  },
  methods: {
    load: function () {
      // id = this.$route.params.id
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/" +
          this.$route.params.id,
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.organization = res.data;
        this.org_id = res.data.org_id;
        this.org_name = res.data.org_name;
        this.org_address = res.data.org_address;
        this.org_nick_name = res.data.org_nick_name;
        this.cdr = res.data.cdr_org_id;
        this.country = res.data.country;
        this.industry = res.data.industry;
        this.fromDate = res.data.starting_month;
        // this.toastMessage(res)  // method call to method2
        // this.items = res.data.results;
      });
    },
    loadCountry() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "organization/vciso-countries",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res.data.data.data, "country details");
        res.data.data.data.map(function (value, key) {
          let a = {
            value: res.data.data.data[key]._id,
            text: res.data.data.data[key].name,
          };
          self.country_options.push(a);
        });
      });
    },
    loadIndustry() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "organization/vciso-industries",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res.data, "industry details");
        res.data.data.data.map(function (value, key) {
          let a = {
            value: res.data.data.data[key]._id,
            text: res.data.data.data[key].name,
          };
          self.industry_options.push(a);
        });
      });
    },
    fetchCDR: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/cdr-search" +
          "?cdr_id=" +
          this.cdr,
      };
      var self = this;
      this.$http(a_options).then((res) => {
        self.cdr_options = [];
        console.log(res.data, "cddddddddrrrrrrrr");
        res.data.map(function (value, key) {
          let a = {
            value: value.org_id,
            text: value.org_name,
            full_details: value,
          };
          self.cdr_options.push(a);
        });
      });
    },
    loadCdr: function (search) {
      if (search.length) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "organization/cdr-search" +
            "?name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.cdr_options = [];
          console.log(res.data, "cddddddddrrrrrrrr");
          res.data.map(function (value, key) {
            let a = {
              value: value.org_id,
              text: value.org_name,
              full_details: value,
            };
            // console.log(res.data[key].profile.organization)
            self.cdr_options.push(a);
          });
        });
      }
    },
    editOrg: function () {
      this.$refs.OrgEditForm.validate().then((success) => {
        if (success) {
          //call to axios
          var self = this;
          console.log("success");
          var data;
          if (this.cdr == "") {
            data = {
              org_name: this.org_name,
              org_address: this.org_address,
              org_nick_name: this.org_nick_name,
              starting_month:this.fromDate,
            };
          } else {
            data = {
              org_name: this.org_name,
              org_address: this.org_address,
              org_nick_name: this.org_nick_name,
              cdr_org_id: this.cdr,
              starting_month:this.fromDate,
            };
          }
          // let data = {
          //   org_name: this.org_name,
          //   org_address: this.org_address,
          //   org_nick_name: this.org_nick_name,
          //   cdr_org_id: this.cdr,
          // };
          const options = {
            method: "PATCH",
            headers: { "content-type": "application/json" },
            data: data,
            url:
              process.env.VUE_APP_BASEURL +
              "organization/organization/" +
              this.$route.params.id +
              "/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.org_id) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Updated Successfully",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$store.dispatch("app/getOrg", this.org_refId);
              this.$router.push({ name: "Organizations" });
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

