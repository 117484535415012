<template>
  <b-card-code
    :title="`Scan Settings: ${organization.org_name}`"
    style="width: 100%"
  >
    <!-- <b-card-code class="bg-light-secondary"> -->

    <validation-observer ref="OrgAddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="saveSettings">
        <!-- Name -->
        <b-form-group
          label="Max No of Parallel Scans Allowed"
          label-for="max_no"
        >
          <validation-provider
            #default="{ errors }"
            name="Max No of Parallel Scans Allowed"
            rules="required"
          >
            <b-form-input
              id="max_no"
              v-model="max_no"
              :state="errors.length > 0 ? false : null"
              name="max_no"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Run port discovery for every asset added?"
          label-for="is_asset_discovery_enabled"
        >
          <b-form-select
            v-model="is_asset_discovery_enabled"
            :options="options"
          >
          </b-form-select>
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid"
          class="my-1"
          size="sm"
        >
          Update
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      max_no: 5,
      is_asset_discovery_enabled: 0,
      options: [
        { value: 0, text: "No" },
        { value: 1, text: "Yes" },
      ],
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/" +
          this.organization.org_id +
          "/get-scan-settings",
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.max_no = res.data.max_parallel_scans_allowed;
        this.is_asset_discovery_enabled = res.data.is_asset_discovery_enabled;
      });
    },
    saveSettings: function () {
      let data = {
        org_id: this.organization.org_id,
        max_no_scans: this.max_no,
        is_asset_discovery_enabled: this.is_asset_discovery_enabled,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/" +
          this.organization.org_id +
          "/update-scan-settings/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Updated Successfully",
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.max_no = res.data.max_parallel_scans_allowed;
      });
    },
  },
};
</script>
</script>
